@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.contato {
    text-align: center;
}
.titulo-contato {
    padding-bottom: 10px;
    font-family: 'HelveticaBold';
}
.texto-contato {
    font-family: 'HelveticaRegular';
    color: #828282;
}
.bg-contato {
    background-color: #EBEBEB;
    padding: 45px;
    margin: 0;
}
.text-link {
    color:#828282;
    font-family: 'HelveticaRegular' !important;
}
.text-link:hover {
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}
.container-contato {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;
}
.item-contato {
    width:200px;
    display: flex;
    flex-direction: column;
    text-align:start;
}
@media screen and (max-width: 620px) {
    .item-contato{
        text-align: center;
    }
}