@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.titulo-fale-conosco {
    font-family: 'HelveticaBold';
}
.subtitulo {
    font-family: 'HelveticaRegular';
    text-align: justify;
    color: #828282;
}
.texto-form {
    font-family: 'HelveticaRegular';
}
.btn-enviar:hover{
    background-color: #000000 !important;
    border-color: #000000 !important;
    color: #ffffff !important;
}
@media screen and (max-width: 620px) {
    .texto-form {
        width: 100%;
    }
}