@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.container{
    flex: 1 0 auto;
}
.titulo-novidades{
    text-align: left;
    margin-top:150px;
    font-family: 'HelveticaBlack';
    margin-bottom: 25px;
    text-transform: uppercase;
    margin-left: 40px;
}

.outsidediv{
    padding: 100px;
    padding-top: 10px;
}

.innerTextProjetos{
    padding:20px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'HelveticaBlack';
    font-size: 5vw;
    color: #ebebeb;
    text-shadow: inset 0px 5px 16px #FFFFFF29;
    opacity: 0.6;
}

/*card style 1: por default um  pouco borrado e escuro e com o nome aparecendo, no hover mostrar só imagem normal*/
.card-style-1 > img{
    filter: brightness(50%) blur(2px);  
}

.card-style-1{
    cursor:pointer;
    position: relative;
    font-family: 'HelveticaLight';
    text-align: center;
}

.card-style-1:hover > img{
    filter: brightness(100%) blur(0px);
}

.card-style-1:hover > p{
    display: none;
}

.card-style-3 > img{
    filter: brightness(50%) ;  
}

.card-style-3{
    cursor:pointer;
    position: relative;
    font-family: 'HelveticaLight';
    text-align: center;
}

.card-style-3:hover > img{
    filter: brightness(100%);
}

.card-style-3:hover > p{
    display: none;
}


.card-style-2 > img{
    filter: brightness(100%) blur(0px);   
}

.card-style-2{
    cursor:pointer;
    position: relative;
    font-family: 'HelveticaLight';
    text-align: center;
}

.card-style-2:hover > img{
    filter: brightness(50%) blur(1.9px);
}

.card-style-2 > p{
    display: none;
}
.card-style-2:hover > p{
    display: unset;
}

.card-style-4 > img{
    filter: brightness(100%);   
}

.card-style-4{
    cursor:pointer;
    position: relative;
    font-family: 'HelveticaLight';
    text-align: center;
}

.card-style-4:hover > img{
    filter: brightness(50%);
}

.card-style-4 > p{
    display: none;
}
.card-style-4:hover > p{
    display: unset;
}

.selector{
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.selector-option{
    margin: 10px;
    padding: 10px;
    font-family: 'HelveticaRegular' !important;
}

.selector-option > p{
    font-family: 'HelveticaRegular' !important;
}

.selector-optionactive{
    margin: 10px;
    padding: 10px;
    font-family: 'HelveticaBold' !important;
    background-color: #000000 !important;
    border-radius: 50px;
    color:#ffffff !important;
}
.selector-optionactive > p{
    font-family: 'HelveticaBold' !important;
}

.selector-option:hover{
    cursor: pointer;
    background-color: gray !important;
    border-radius: 50px;
    color:#ffffff !important;
}

@media screen and (max-width: 620px) {
    .titulo-novidades {
        margin-top:40px;
    }
    .innerTextProjetos{
        top:40%;
        font-size: 10vw;
    }
    .innerText{
        top:35%;
        font-size: 30vw;
    }
    .outsidediv{
        padding:30px;
        
    }
}