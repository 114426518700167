@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.bg-consultoria {
    background-color: #EBEBEB;
    padding: 45px;
    margin: 0;
}
.container-consultoria {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;
}
/* ------------------------------------------ */
.descricao-consultoria{
    font-family: 'HelveticaRegular';
    color: #828282;
    text-align: center;
}
.tamanho-card {
    height: 300px;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
}
.card {
    width: 30%;
    margin-bottom: 20px;
    /* transition: 0.3s; */
    border-radius: 50px;
}
.card-header{
    position:relative;
    text-align:center;
}
.img-consultoria {
    width: 150px;
    height: 150px;
}
.card-body {
    /* padding: 10px 10px; */
    text-align: center;
    font-size: 14px;
    color: #828282;
}
.card-body .btn {
    display: block;
    color: #fff;
    text-align: center;
    background: #000000;
    margin-top: 30px;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 50px;
    width: fit-content;
    margin: 0 auto;
    font-family: 'HelveticaBold';
}

.consultoriaTema{
    font-family: 'HelveticaBold';
    color: #000000;
    text-align: center;
}

.consultoriaTitle{
    font-family: 'HelveticaBlack';
    font-size: 39px;
    color: #000000;
    text-align: center;
}
.btn-saibamais{
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 50px;
    padding: 10px;
    text-decoration: none;
    width: 147px;
    font-family: 'HelveticaBold';
    margin: 0 auto;
    margin-top: 15px;
}
.btn-saibamais:hover{
    background-color: #c4c4c4 !important;
    color: #000000 !important;
}
/* Centered text */
.top-right-consultoria {
    background-color: #D8C48E;
    width:70px;
    height:70px;
    border-radius: 50px;
    position: absolute;
    top: -35px;
    right: -35px;
    align-content:center;
}
.mais-escolhido {
    font-family: 'HelveticaBold';
    font-size: 14px;
}
@media screen and (max-width: 1000px) {
    .card {
      width: 40%;
    }
}
@media screen and (max-width: 620px) {
    .container {
      width: 100%;
    }
    .heading {
      padding: 20px;
      font-size: 20px;
    }
    .card {
      width: 80%;
      margin-bottom: 50px;
    }
    .tamanho-card{
        height: 350px;
    }
}

.containerRibbon {
    --d: 6px; /* folded part */
    --c: blue; /* color */
    --f: 16px; /* ribbon font-size */

    position: relative;
    font-family: 'helveticaRegular';
}

.containerRibbon::before {
    content: attr(data-ribbon);
    position: absolute;
    font-size: var(--f);
    top: 0;
    right: 0;
    transform: translate(29.29%, -100%) rotate(45deg);
    color: #fff;
    text-align: center;
    border: 1px solid transparent;
    border-bottom: 0;
    transform-origin: bottom left;
    padding: 5px 35px calc(var(--d) + 5px);
    background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
      no-repeat var(--c);
    background-clip: padding-box;
    clip-path: polygon(0 0,100% 0,100% 100%,calc(100% - var(--d)) calc(100% - var(--d)),var(--d) calc(100% - var(--d)),0 100%);
    -webkit-mask: linear-gradient(135deg,transparent calc(50% - var(--d) * 0.707),#fff 0) bottom left,
      linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)bottom right;
    -webkit-mask-size: 300vmax 300vmax;
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
}