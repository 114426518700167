@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.bannerContainerBanner{
    position: relative;
    text-align: center;
}
.innerTextBanner3{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'HelveticaBlack';
    font-size: 10vw;
    text-shadow: inset 0px 5px 16px #FFFFFF29;
    opacity: 0.6;
}
.myCarrouselBanner{
    cursor: pointer;
}

