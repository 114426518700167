@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.bannerContainerUs{
    position: relative;
    text-align: center;
}
.innerTextUs{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'HelveticaBlack';
    font-size: 10vw;
    color: #ebebeb;
    text-shadow: inset 0px 5px 16px #FFFFFF29;
    opacity: 0.6;
}
.carroselImageUs{
    border-radius: 7%;
    max-height: 500px;
}