@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.titulo-consultoria-interna{
    text-align: left;
    margin-top: 150px;
    font-family: 'HelveticaBlack';
    margin-bottom: 25px;
    text-transform: uppercase;
    margin-left: 40px;
}
.subtitulo-consultoria-interna{
    margin-bottom: 25px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: 'HelveticaRegular';
}

.questioneer{
    text-align: center;
    padding-left: 25%;
    padding-right: 25%;
    margin-bottom: 50px;
}
.questionnerTextField{
    width: 100%;
    margin-top:20px !important;
    color:black;
    background-color: #E6E7E8;
    border-radius: 20px;
    /* border-radius: 10% !important; */
}
.formTitle{
    font-family: 'HelveticaBlack';
}
.formText{
    font-family: 'HelveticaRegular';
}

.questionnerTextField fieldset{
 border-radius: 20px;

}

.btnSubmit{
    background-color: #000000 !important;
    color:#FFFFFF !important;
    font-family: 'HelveticaBold'!important;
}

.btnSubmit:hover{
    background-color: #c4c4c4 !important;
    color:#000000 !important;
}
.optionText > span{
    font-family: 'HelveticaRegular' !important;
}


@media screen and (max-width: 830px) {
    .titulo-consultoria-interna {
        margin-top:40px;
    }
    .questioneer {
        padding-left: 10%;
        padding-right: 10%;
    }
}