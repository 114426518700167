@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.breadcrumbs > ol > li > a{
    font-family: 'HelveticaRegular' !important;
}

.container{
    flex: 1 0 auto;
}
.projetoTitle{
    width: 80%;
    margin: 0 auto;
    margin-top: 140px;
    font-family: 'HelveticaRegular' !important;
}

.projetoTile > nav > ol > li > a{
    font-family: 'HelveticaRegular' !important  ; 
}

.projectInfo{
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
}
.projetoInfoTitle{
    margin-left: 10px   ;
    margin-right: 10px;
    margin-top: 6px;
    font-family:'HelveticaBold' ;
}
.projectInfoText{
    margin-top:9px; 
    color:#828282;
    font-family:'HelveticaRegular' ;
}
.projectInfoAbout{
    margin-top:9px; 
    color:#828282;
    font-family:'HelveticaRegular' ;
    font-size: 20px;
    margin-bottom:50px
}
.link360{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom:150px;
    text-align: center;
}
.projeto-360{
    text-decoration: none;
    color: #000000;
    font-family: 'HelveticaBlack';
}

@media screen and (max-width: 620px) {
    .projetoTitle{
        margin-top: 45px;
    }
}