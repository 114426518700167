@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.bannerContainerGeneric{
    margin: 0 auto;
    margin-top: 30px;
    width: 40%;
    position: relative;
    text-align: center;
}

.bannerContainerBig{
    margin: 0 auto;
    margin-top: 130px;
    width: 40%;
    position: relative;
    text-align: center;
}
.innerText{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'HelveticaBlack';
    font-size: 10vw;
    color: #ebebeb;
    text-shadow: inset 0px 5px 16px #FFFFFF29;
    opacity: 0.6;
}

.projetoInfo{
    margin-top: 140px
}

.carroselImageProjetoGeneric{
    border-radius: 10%;
}

.titulo-projeto{
    text-align: left;
    margin-top:40px;
    font-family: 'HelveticaBlack';
    margin-bottom: 25px;
    text-transform: uppercase;
}

@media screen and (max-width: 620px) {
    .bannerContainerGeneric{
        margin-top: 40px;
        width:80%;
    }
}