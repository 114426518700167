@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.container{
    flex: 1 0 auto;
}
.titulo-novidades{
    text-align: left;
    margin-top:150px;
    font-family: 'HelveticaBlack';
    margin-bottom: 25px;
    text-transform: uppercase;
    margin-left: 40px;
}
.novidadeContainer{
    cursor: pointer;
    margin-top:60px;
    position: relative;
    font-family: 'HelveticaRegular';
    text-align: center;
    margin-right: 40px;
    margin-left: 40px;
}
.innerText2{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'HelveticaBlack';
    font-size: 10vw;
    color: #ebebeb;
    text-shadow: inset 0px 5px 16px #FFFFFF29;
    opacity: 0.6;
}
.innerText3{
    text-align:justify;
    font-family: 'HelveticaRegular';
}
.top-right {
    background-color: #D8C48E;
    width:100px;
    height:100px;
    border-radius: 50px;
    position: absolute;
    top: -20px;
    right: -20px;
    align-content:center;
}
.em-breve {
    font-family: 'HelveticaBold';
    font-size: 18px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.em-breve-first{
    margin-top: 22px;
}

.card-style-novidade > a > img{
    filter: brightness(100%) blur(0px);   
}

.card-style-novidade{
    cursor:pointer;
    position: relative;
    font-family: 'HelveticaLight';
    text-align: center;
}

.card-style-novidade:hover > a > img{
    filter: brightness(50%) blur(1.9px);
}


@media screen and (max-width: 620px) {
    .titulo-novidades {
        margin-top:40px;
    }
    .innerText2{
        top:12%
    }
    .top-right{
        width: 70px;
        height: 70px;
    }
    .em-breve-first{
        margin-top: 10px;
    }
}