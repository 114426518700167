@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.rodape{
    flex-shrink: 0;
    text-align: center;
    background-color: #ffffff;
    color: #828282;
    font-family: 'HelveticaLight';
    padding: 10px;
}