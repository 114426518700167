@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.container-not-found{
    margin-top: 190px;
    margin-bottom: 190px;
    padding: 35px;
}
.titulo-not-found{
    text-align: center;
    font-family: 'HelveticaBlack';
    margin-bottom: 25px;
    font-size: 80px;
}
.subtitulo-not-found{
    text-align: center;
    font-family: 'HelveticaBold';
    margin-bottom: 25px;
    font-size: 25px;
}
.texto-not-found{
    text-align: center;
    font-family: 'HelveticaRegular';
    margin-bottom: 25px;
    font-size: 20px;
}

.btnGoBack{
    /* background-color: #000000 !important; */
    color: #000000 !important;
    border-color: #000000 !important;
    text-transform: uppercase !important;
    font-family: 'HelveticaBold' !important;
}
.btnGoHome{
    background-color: #000000 !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-family: 'HelveticaBold' !important;
}
@media screen and (max-width: 620px) {
    .container-not-found{
        margin-top: 8px;
        margin-bottom: 50px;
    }
}