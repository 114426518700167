@font-face {
  font-family: 'HelveticaBold';
  src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.btn-enviar-consultoria{
  border-color: #000000 !important;
  color:#000000 !important;
  font-family: 'HelveticaBold'!important;
  margin-top:40px!important;
  margin-bottom:40px!important;
}
.btn-enviar-consultoria:hover{
  background-color: #000000 !important;
  border-color: #000000 !important;
  color:#ffffff !important;
}

.responsiveOptionDiv{
  width:100%;
  margin-top:15px;
}

.responsiveDescription{
  display:none
}

.consultoriaType{
  font-family: 'HelveticaBold';
}

.consultoriaText{
  font-family: 'HelveticaRegular';
  color: #828282;
}

.itensLight{
  font-family: 'HelveticaRegular';
}

.itensBold{
  font-family: 'HelveticaBold';
}	

.containerRibbon {
    --d: 6px; /* folded part */
    --c: blue; /* color */
    --f: 16px; /* ribbon font-size */

    position: relative;

}

.containerRibbon::before {
    content: attr(data-ribbon);
    position: absolute;
    font-size: var(--f);
    top: 0;
    right: 0;
    transform: translate(29.29%, -100%) rotate(45deg);
    color: #fff;
    text-align: center;
    border: 1px solid transparent;
    border-bottom: 0;
    transform-origin: bottom left;
    padding: 5px 35px calc(var(--d) + 5px);
    background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
      no-repeat var(--c);
    background-clip: padding-box;
    clip-path: polygon(0 0,100% 0,100% 100%,calc(100% - var(--d)) calc(100% - var(--d)),var(--d) calc(100% - var(--d)),0 100%);
    -webkit-mask: linear-gradient(135deg,transparent calc(50% - var(--d) * 0.707),#fff 0) bottom left,
      linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)bottom right;
    -webkit-mask-size: 300vmax 300vmax;
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
}


@media screen and (max-width: 899px) {
  .responsiveList{
    display: none;
  }

  .responsiveDescription{
    display:unset;
    font-family: 'HelveticaBold';
  }
  .responsiveOptionDiv{
    width: auto;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }

  .responsiveBorder{
    border-radius: 20px !important;
    margin-top:35px !important;
  }
}