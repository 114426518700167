@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBlack';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.sobre-nos{
    width: 100%; 
    height: 100%;
    min-height: 100%; 
    display: flex; 
    flex-direction: column;
}
.container-sobre-nos{
    display: flex;
    margin-top: 150px;
    flex-wrap: wrap;
}
.titulo-sobre-nos{
    text-align: left;
    font-family: 'HelveticaBlack';
    margin-bottom: 25px;
    text-transform: uppercase;
}
.texto-sobre-nos{
    width: 40%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
    font-family: 'HelveticaRegular';
    color: #000000;
}

.texto-sobre-nos > p{
    color: #828282;
}

.img-sobre-nos{
    margin: 0 auto;
    width: 40%;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    height: auto;
}
.titulo-nossa-equipe{
    margin-left: 20px;
    padding: 30px;
    text-align: left;
    font-family: 'HelveticaBlack';
    margin-bottom: 25px;
    text-transform: uppercase;
}
.equipe-card-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    flex-wrap: wrap;
}
.parceiros-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px;
    flex-wrap: wrap;
}
.nossa-equipe{
    background-color: #F6F6F6;
    width: 100%;
}
.nosso-escritorio{
    display: flex;
    flex-wrap: wrap;
    background-color: #F6F6F6;
    width: 100%;
}
.carrosel-sobre-nos{
    width: 40%;
    margin :0 auto;
    padding: 30px;
}
.titulo-integrante{
    font-family: 'HelveticaBlack';
    font-size: 24px;
}
.equipe-card{
    margin-left: 20px;
    width: 35%
}
.cargo{
    font-family: 'HelveticaRegular';
    font-size: 20px;
    color: rgba(0, 0, 0, 0.76);
}
.bio{
    font-family: 'HelveticaRegular';
    font-size: 16px;
    color: #828282;
}
.img-parceiros{
    display:flex;
    justify-content: center;
    margin-bottom: 150px;
    padding: 50px;
}
.img-parceiro-jc{
    width: 276px;
    height: 190px;
}
.circulo{
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	float: center;
	margin: 15px;
    border: 2px solid #c4c4c4;
    border-radius: 100px;
    display: flex;
    justify-content: center;
}
.especialidades-container{
    padding: 40px;
}
.especialidade-item{
    min-height: 300px;
}
.titulo-especialidade{
    font-family: 'HelveticaBold';
    font-size: 18px;
}
.texto-especialidade{
    font-family: 'HelveticaRegular';
    color:#828282;
    font-size: 16px;
}

.carroselImage{
    border-radius: 7%;
    max-height: 500px;
}
@media screen and (max-width: 620px) {
    .carroselImage{
        max-height: 320px;
    }
    .texto-sobre-nos {
        width: unset;
    }
    .container-sobre-nos{
        margin-top: 8px;
    }
    .img-sobre-nos{
        width: 100%;
    }
    .equipe-card{
        width: 100%;
        margin-bottom: 40px;
    }
    .especialidade-item{
        text-align: center;
        justify-content: center;
        min-width: 100%;
        min-height: 0;
    }
    .circulo{
        margin: 0 auto;
    }
    .titulo-especialidade{
        font-size: 25px;
    }
    .texto-especialidade{
        font-size: 17px;
    }
    .carrosel-sobre-nos{
        width: unset;
    }
}