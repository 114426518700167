@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaLight';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.titulo-visita {
    font-family: 'HelveticaBold';
}
.subtitulo-map{
    color:#828282;
    font-family: 'HelveticaRegular';
}
.icon-map{
    height: fit-content;
    margin: auto 0 !important;
    margin-left: 10px !important;
    color: #000000 !important;
    background-color: #ffffff !important;
}
.icon-map:hover{
    background-color: #EBEBEB !important;
}
.medio-titulo{
    font-family: 'HelveticaRegular';
    font-size: 14px;
}
.mymap{
    border-width: 0;
    border-radius: 30px;
    width:500px;
    height: 300px;
}
.textColoumn{
    text-align: end;
}
@media screen and (max-width: 620px) {
    .responsiveMap{
        margin:0 auto;
    }
    .textColoumn{
        text-align: justify;
    }
    .mymap{
        width:250px;
        height: 250px;
    }
    .titulo-visita{
        text-align: left;
    }
    .subtitulo-map{
        text-align: left;
    }
}

