@font-face {
    font-family: 'HelveticaBold';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../fonts/Helvetica/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.myNavBar{
    position:absolute;
    z-index:1;
    background-color:rgba(255,255,255,.6) !important;
    backdrop-filter: blur(8px);
    color:#000 !important;
    
}
.menuOption{
    color:black !important;
    font-family: 'HelveticaRegular' !important;
    text-transform: none !important;
    font-size: 16px !important;
    transition-duration: 0s !important;
}

.menuOptionSelected{
    font-family: 'HelveticaBold' !important;
    text-transform: none !important;
    font-size: 16px !important;
    transition-duration: 0s !important;
    background-color: #000000 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 25px !important;
    color:#ffffff !important;
}

.menuOption:hover {
    background-color: #000000 !important;
    border-radius: 50px;
    color:#ffffff !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 25px !important;
}
.icon-nav{
    height: 50%;
    margin: auto 0 !important;
    margin-left: 10px !important;
    color: #000000 !important;
    background-color: #ffffff !important;
}
.icon-nav:hover{
    background-color: #EBEBEB !important;
}
.logo2{
    height: 90px !important;
    width: 170px !important;
    padding: 10px;
}
@media screen and (max-width: 620px) {
    .myNavBar{
        position:unset !important;
        background-color:rgba(255,255,255,.6) !important;
        backdrop-filter: blur(8px);
        color:#000 !important;
    }
    .icon-nav{
        background-color: rgb(246,246,246) !important;
    }
    .logo2{
        text-align: center;
    }
}